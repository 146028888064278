@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 111%;
  }
}

#features{
padding-top: 60px;
padding-bottom: 60px;
}

.team-img{
  height: 140px;
}

.procedure-caption{
  padding-bottom: 50px;
}
.title-procedure{padding-bottom: 5px;}

#procedures{
  padding-bottom: 60px;
}

#procedure-detail-container{
  padding-bottom: 50px;
}

.video-container {
  position: relative;
  padding-top: 30px;
  height: 100hv;
}